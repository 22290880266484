<template>
    <div class="theory-libaray-content">
        <div class="header-line">
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/admin/theorymanage' }"><span>理论考试管理</span></el-breadcrumb-item>
                <el-breadcrumb-item>题库管理</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="btn-blue" type="primary" @click="addEditLibaray()">添加专业大类</el-button>
        </div>
        <div class="data-list">
            <el-scrollbar style="height: 100%;">
                <el-table :data="tableData" ref="tableData" row-key="theory_topic_category_id"
                          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                          :expand-row-keys="expands"
                          :header-row-class-name="tableHeaderClassName"
                          class="libaray-table"
                          header-cell-class-name="table-header-deep"
                >
                    <el-table-column label="题库名称" prop="name"></el-table-column>
                    <el-table-column label="操作" width="260" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="deep-blue" v-if="scope.row.pid === 0" @click="addSecondCate(scope.row)">添加二级大类</el-button>
                            <el-button type="text" class="deep-blue" @click="addEditLibaray(scope.row)">编辑</el-button>
                            <el-button type="text" class="light-red" @click="delLibaray(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-scrollbar>
        </div>
        <div class="data-page">
            <el-pagination
                layout="prev, pager, next, jumper"
                :current-page.sync="page"
                @current-change="handleCurrentChange"
                :total="total">
            </el-pagination>
        </div>
        <div class="add-dialog">
            <el-dialog title="添加专业大类" :visible.sync="dialogVisible" width="30%" label-width="100px" @close="handleClose" custom-class="libaray-dialog">
                <div class="dialog-content">
                    <el-form :model="form" :rules="rules" class="library-form" ref="libraryForm">
                        <el-form-item label="父类名称" v-if="pid">
                            <span class="father-libaray">{{topicLibaray}}</span>
                        </el-form-item>
                        <el-form-item label="专业名称" prop="name">
                            <el-input v-model="form.name" class="input-text" @keydown.en.enter.native="submitForm('libraryForm')"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <div class="el-btn-group">
                                <el-button @click="dialogVisible = false">取消</el-button>
                                <el-button type="primary" class="btn-blue" @click="submitForm('libraryForm')">确定</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>

            </el-dialog>
        </div>

    </div>
</template>

<script>
import {topicCateAdd, topicCateList, topicLibarayDel} from "@/utils/apis";

export default {
    name: "theoryLibaray",
    data() {
        return {
            dialogVisible: false,
            form: {
                name: ""
            },
            rules: {
                name: [
                    { required: true, message: "请输入专业大类名称", trigger: 'blur' },
                    { min: 3, max: 50, message: "专业大类名称在3-20个字之间", trigger: 'blur'}
                ]
            },
            page: 1,
            total: 0,
            tableData: [],
            expands: [],
            topicLibaray: '',
            pid: 0,
            currenId: 0,
        }
    },
    created() {
        this.topicLibraryList();
    },
    methods: {
        tableHeaderClassName() {
            return 'table-header-deep'
        },
        addSecondCate(row) {
            console.log('row', row);
            this.pid = row.theory_topic_category_id;
            this.topicLibaray = row.name;
            this.form.name = void 0;
            this.dialogVisible = true;
            //展开
            this.$refs['tableData'].toggleRowExpansion(row, true);
        },
        addEditLibaray(row) {
            console.log('row', row);
            if(!!row) {
                this.currenId = row.theory_topic_category_id;
                this.form.name = row.name;
            } else {
                this.currenId = void 0;
                this.form.name = void 0;
            }
            this.dialogVisible = true;
        },
        delLibaray(row) {
            this.$confirm('此操作会将该题库下的所有题目删除，是否继续？', '删除题库', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'deep-blue-confirm'
            }).then(() => {
                topicLibarayDel({theory_topic_category_id: row.theory_topic_category_id}).then(res => {
                    console.log('res', res);
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1500,
                            onClose: () => {
                                this.topicLibraryList();
                            }
                        });
                    } else {
                        this.$message.warning(res.msg);
                    }
                }).catch(err => {
                    this.$message.warning('操作失败')
                    console.log('error', err);
                })
            }).catch(() => {
                console.log('取消操作');
            })
        },
        handleCurrentChange(val) {
            this.page = val;
            this.topicLibraryList();
        },
        topicLibraryList() {
            let param = {
                paging: 1,
                page: this.page,
                pageSize: 10
            };
            topicCateList(param).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            }).catch(err => {
                console.log('err', err);
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let formdata = {
                        name: this.form.name
                    }
                    if (this.pid) {
                        formdata.pid = this.pid;
                    }
                    if (this.currenId) {
                        formdata.theory_topic_category_id = this.currenId;
                    }
                    topicCateAdd(formdata).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                duration: 1500,
                                onClose: () => {
                                    this.dialogVisible = false;
                                    this.topicLibraryList();
                                    this.handleClose();
                                }
                            });
                        } else {
                            this.$message.warning(res.msg);
                        }
                    }).catch(err => {
                        console.log('err', err);
                    })
                }
            })
        },
        handleClose() {
            this.pid = 0;
            this.topicLibaray = "";
            this.currenId = 0;
            this.$refs['libraryForm'].resetFields();
        }

    }
}
</script>

<style scoped lang="scss">
.theory-libaray-content {
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    width: 100%;
    height: 100%;
    padding: 30px;
    .header-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .input-text {
        width: 70%;
    }
    .el-btn-group {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .deep-blue {
        &::v-deep.el-button--text {
            color: #1122D8;
            font-size: 14px;
        }
    }
    .light-red {
        &::v-deep.el-button--text {
            color: #EB6547;
            font-size: 14px;
        }
    }
    .data-list {
        width: 100%;
        height: calc(100% - 85px);
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        &::v-deep .el-table {
            &.libaray-table {
                margin-top: 10px;
            }
            .table-header-deep {
                background-color: rgb(242, 243, 250);
            }
        }
    }
    .data-page {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 35px;
    }
}
.add-dialog {
    .father-libaray {
        font-weight: 600;
        color: #999999;
    }
    &::v-deep .el-dialog {
        .el-dialog__header {
            background-color: #1122D8;
            color: #FFF;
            .el-dialog__title {
                color: #FFF;
            }
            .el-dialog__headerbtn {
                .el-dialog__close {
                    color: #FFF;
                }
            }
        }
    }
}


</style>